<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form ref="equipmentForm" v-model="isValid">
      <v-container>
        <v-row class="mt-3">
          <v-col cols="12" md="8">
            <h5>What equipment do you have?</h5>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined>What equipments are available for the venue?</v-card>
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col
            cols="12"
            md="8"
            v-for="(equipment, index) in form.equipments"
            :key="index"
            class="pr-3 my-0 equipment-height"
          >
            <label :class="{ 'text--secondary': !form.equipments[index].selected }"
              ><i :class="form.equipments[index].icon + ' mr-3'"></i> {{ form.equipments[index].name }}</label
            >
            <v-checkbox class="float-right mt-0" v-model="form.equipments[index].selected" :key="index"></v-checkbox>
          </v-col>
        </v-row>

        <v-row class="mt-8">
          <v-col cols="12" md="8">
            <h5>What other equipment do you have?</h5>
          </v-col>
          <v-col cols="12" md="4">
            <v-card color="" class="px-3 py-2 default" outlined
              >Add a new equipment and click <strong>+</strong> icon button to the right side</v-card
            >
          </v-col>
        </v-row>

        <v-row class="my-0">
          <v-col cols="12" md="8">
            <v-text-field
              label="Other equipment name"
              v-model="newEquipment"
              append-icon="mdi-plus"
              :rules="[getRules.string]"
              @keydown.enter.prevent="addEquipment"
              @click:append="addEquipment"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn color="primary" @click="this.continue" class="float-right mt-5 mr-3">Continue</v-btn>
    <v-btn
      color="secondary"
      class="float-right mt-5 mr-1"
      @click="submit('draft')"
      v-if="(getVendor && getVendor.status !== 'active') || getIsVendorUpdate === false"
      >Save As Draft</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Equipments",
  props: ["name", "index", "form"],
  components: {},
  data: () => ({
    newEquipment: "",
    isValid: true
  }),
  computed: {
    ...mapGetters(["getVendor", "getIsVendorUpdate", "getRules"])
  },
  methods: {
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
    continue() {
      this.$emit("locationNextStep", this.index);
    },
    addEquipment() {
      if (this.$refs.equipmentForm.validate()) {
        if (this.newEquipment.replace(/ /g, "") !== "") {
          this.form.equipments.push({
            name: this.newEquipment,
            icon: "mdi mdi-auto-fix",
            selected: false
          });
          this.newEquipment = "";
        }
      }
    }
  }
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}

.color-grey {
  color: #f9f9f9;
}
.equipment-height {
  height: 35px;
}

.pointer {
  cursor: pointer;
}
</style>
